<template lang="pug">
.bg-blue-50.mb-24
  .container
    .max-w-4xl.mx-auto
      .prices-component
        .col-1
          h1 Accede a todas las ventajas
          listComponent(:listText="listText")
        .col-2
          CardComponent
            paragraphCard Prueba de forma ilimitada todas las funcionalidades durante {{ trialPeriod }}
            paragraphCard.price {{ trialPrice }}
            paragraphCard Después su suscripción se renovará al plan mensual en caso de no cancelarla por <strong>{{ subscriptionPrice }}/mes</strong>.
            router-link(:to="{ name: 'builder/select-template' }")
              buttonCard Crear mi CV
</template>

<script>
import ParseCash from "@/composables/parse-cash";

import CardComponent from "./components/card.vue";
import paragraphCard from "./components/card-p.vue";
import buttonCard from "./components/card-button.vue";
import listComponent from "./components/list.vue";

const { parseCash } = ParseCash();

export default {
  name: "prices-component",
  components: {
    CardComponent,
    paragraphCard,
    buttonCard,
    listComponent,
  },
  data: () => ({
    listText: [
      "Descarga de forma ilimitada tu CV desde cualquier lugar.",
      "Crea y modifica tu CV siempre que lo necesites.",
      "Utiliza plantillas completamente personalizables y únicas.",
      "Comparte con un click tu CV.",
      "Sín compromiso, cancela la cuenta cuando quieras.",
    ],
  }),

  computed: {
    defaultProduct() {
      return this.$store.getters["ProductsStore/getproductDefault"];
    },
    trialPrice() {
      return parseCash(
        this.defaultProduct.price_trial,
        this.defaultProduct.product_currency,
        this.defaultProduct.product_sign
      );
    },
    subscriptionPrice() {
      return parseCash(
        this.defaultProduct.price_subscription,
        this.defaultProduct.product_currency,
        this.defaultProduct.product_sign
      );
    },
    trialPeriod() {
      const isInHours = this.defaultProduct.trial_period == 1;
      if (isInHours) return "24 horas";
      return this.defaultProduct.trial_period + " Días";
    },
  },
};
</script>

<style lang="scss" scoped>
.prices-component {
  @apply flex gap-12 py-24 flex-col items-center  md:flex-row;

  .col-1 {
    @apply flex flex-col justify-center flex-grow md:w-2/4 lg:w-3/5;
    h1 {
      @apply font-bold text-3xl pr-4 text-gray-700 text-center md:text-left;
    }
  }
  .col-2 {
    @apply flex-shrink-0 flex-grow  md:w-2/4 lg:w-2/5;
  }
}
</style>
